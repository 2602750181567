import React from 'react';
import Navigation from '../sub-components/Navbar/Navigation.jsx'


function NavBar() {
    return (
      <Navigation />
    );
}

export default NavBar;